<template>
    <div class="container h-100">
 
     
 
       <div class="container py-5 h-100">
          <!-- class="d-flex justify-content-center align-items-center h-100" -->
 
         <div class="d-flex justify-content-end align-items-center">
           <h5 class="m-0 p-0">Don’t have an account?</h5>
           <router-link :to="{ name: 'SignUp', query: $route.query}"><span class="auth_nav_btn ms-2">Sign up</span></router-link>
         </div>
 
 
         <div class="d-flex justify-content-center align-items-center h-100 flex-column">
          <div id="login_form_div">
             <div class="card-body">
                <p style="font-weight: 500; font-size: 28px; text-align: center;">Log in</p>
                <div>

 
 
                 <div>
                     <form v-on:submit="login_child">
                       <div class="form-group mb-3">
                           <input type="text" class="form-control" id="inputUsername" name="username" placeholder="Username" style="border: 2px solid #DCE0EA; border-radius: 12px;">
                       </div>
                       <div class="form-group mb-1">
                           <div class="input-group" id="show_hide_password">
                               <input v-if="rldata.showPassword" class="form-control" type="text" name="password" placeholder="Password" style="border: 2px solid #DCE0EA; border-radius: 12px 0px 0px 12px;">
                               <input v-else class="form-control" type="password" name="password" placeholder="Password" style="border: 2px solid #DCE0EA; border-radius: 12px 0px 0px 12px;">
                               <div class="input-group-append">
                                   <span @click="toggleShowPassword" class="input-group-text h-100"><i :class="{ 'fa fa-eye-slash': !rldata.showPassword, 'fa fa-eye': rldata.showPassword }" aria-hidden="true"></i></span>
                               </div>
                           </div>
                       </div>
                       <!-- <p style="text-align:end;" class="mb-4"><router-link :to="{ name: 'SignUp'}"><small class="text-dark">Forgot password?</small></router-link></p> -->
 
                       <button type="submit" class="mt-5 btn w-100 auth_submit_btn">Log In</button>
 
                       <!-- <div v-on:click="this.$root.login_social" class="d-flex justify-content-center align-items-center my-3" style="background: #FFFFFF; box-shadow: 0px 4px 0px #DEDEDE; border-radius: 12px; padding:5px; cursor:pointer;"  data-uloginbutton="Google">
                             <div class="social_img_cards social_google" data-uloginbutton="Google"></div>
                             <p style="font-weight: 500; color:black;" class="m-0 p-0 ms-2" data-uloginbutton="Google">Google</p>
                       </div> -->
                     </form>
                 </div>
 
 
                </div>
             </div>
          </div>
 
         </div>
 
       </div>
    </div>
 </template>
 
 <script>
 var rldata = {
   user: null,
 
   child_parent_switcher: "child",
   showPassword: false,
 }
 
 import router from "@/router"
 import axios from "axios"
 
 export default {
   name: "Login",
   data () {
     return {
       rldata
     }
   },
   methods: {
     login_parent: function(e) {
       e.preventDefault()
       let email = e.target.elements.email.value;
       let password = e.target.elements.password.value;
 
       this.$root.userLogin(email, password);
     },
 
     login_child: function(e) {
       e.preventDefault()
       let username = e.target.elements.username.value;
       let password = e.target.elements.password.value;
 
       this.$root.childLogin(username, password);
     },
     toggleShowPassword() {
       this.rldata.showPassword = !this.rldata.showPassword;
     }
   },
   mounted () {
     $(document).ready(function() {
         // $("#show_hide_password a").on('click', function(event) {
         //     event.preventDefault();
         //     if($('#show_hide_password input').attr("type") == "text"){
         //         $('#show_hide_password input').attr('type', 'password');
         //         $('#show_hide_password i').addClass( "fa-eye-slash" );
         //         $('#show_hide_password i').removeClass( "fa-eye" );
         //     }else if($('#show_hide_password input').attr("type") == "password"){
         //         $('#show_hide_password input').attr('type', 'text');
         //         $('#show_hide_password i').removeClass( "fa-eye-slash" );
         //         $('#show_hide_password i').addClass( "fa-eye" );
         //     }
         // });
 
         $("#child_play_div").css({
           'width': ($("#login_form_div").width() + 'px')
         });
     });
 
     try{
         this.$root.getUserData(this.rldata);
 
         // if(this.rldata.user != null){
         //   router.push("/dashboard");
         // }
       } catch(err){
         this.$root.logout();
       }
   },
    watch: {
      "rldata.user": function(handler){
        if(handler != null) {
          router.push('/dashboard')
        }
      }
    }
 }
 </script>
 
 <style lang="scss" scoped>
 $main-color: #ff9012;
 $hover-color: #fdca6b;
 
 .card{
   background-color: rgba(0,0,0,0.5) !important;
 }
 
 .input-group-prepend span{
   width: 40px;
   height: 100%;
   background-color: $main-color;
   color: black;
   border:0 !important;
 }
 
 input:focus{
   outline: 0 0 0 0  !important;
   box-shadow: 0 0 0 0 !important;
 }
 
 .login_btn{
   color: black;
   background-color: $main-color;
   width: 100px;
 }
 
 .login_btn:hover{
   color: black;
   background-color: $hover-color;
 }
 
   .social_img_cards {
     // transform: scale(1.3);
     cursor: pointer;
     padding: 0px; 
     outline: none; 
     border: none; 
     border-radius: 0px;
     position: relative; 
     display: inherit; 
     width: 32px; 
     height: 32px; 
     left: 0px; 
     top: 0px; 
     box-sizing: content-box;
   }
 
   .social_google{
     background: url('/resource/img/google_ico.svg');
   }
   .social_clever{
     background: url("/resource/img/social_logos.png") 0px -104px no-repeat;
   }
 
   .btn-outline-primary,
   .btn-outline-primary:focus {
     color: #8C90FF;
   }
 
   .btn-outline-primary:active {
     background: #8C90FF;
     color: white;
   }
 </style>